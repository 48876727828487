import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  Anchor,
  AppShell,
  Box,
  Button,
  Container,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Transition,
} from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { useForm, zodResolver } from '@mantine/form';
import { IconLock, IconMail } from '@tabler/icons';
import { z } from 'zod';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/nextjs';
import { login } from '@/features/auth';
import { getServerSidePropsWithSession } from '@/lib/middlewares';
import Logo from '@/public/images/logo.svg';

interface State {
  // ログインに失敗したかどうか
  authFailed: boolean;
  // 通信中どうかどうか
  loading: boolean;
}

interface LoginFormValues {
  email: string;
  password: string;
}

const schema = z.object({
  email: z
    .string()
    .email('メールアドレスの形式が正しくありません')
    .min(1, 'メールアドレスを入力してください'),
  password: z.string().min(1, 'パスワードを入力してください'),
});

export const getServerSideProps = getServerSidePropsWithSession(({ req }) => {
  const user = req.session.get('user');
  if (typeof user !== 'undefined') {
    // ログイン済みの場合はホームへリダイレクト
    return {
      redirect: {
        destination: '/admin',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
});

/**
 * ページコンポーネント
 * @constructor
 */
const LoginPage = (): JSX.Element => {
  const [state, setState] = useSetState<State>({
    authFailed: false,
    loading: false,
  });
  const router = useRouter();
  const form = useForm<LoginFormValues>({
    validate: zodResolver(schema),
    initialValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = form.onSubmit(async ({ email, password }) => {
    try {
      setState({ loading: true });
      await login(email, password);
      setState({ authFailed: false });
      await router.push('/admin');
    } catch (e: unknown) {
      setState({ authFailed: true });
      if (!(e instanceof AxiosError && e.response?.status === 400)) {
        // 想定外のエラーなので Sentry でキャプチャ
        Sentry.captureException(e);
      }
    } finally {
      setState({ loading: false });
    }
  });

  return (
    <>
      <Head>
        <title>サインイン | Ranking Master</title>
      </Head>

      <AppShell
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
            height: '100vh',
          },
        })}
      >
        <Container className="flex flex-col items-center" size={420} my={40}>
          <Image src={Logo} alt="Logo" />

          <Paper className="w-full" shadow="md" p={30} mt={30} radius="md">
            <LoadingOverlay visible={state.loading} />

            <form onSubmit={onSubmit}>
              <TextInput
                type="email"
                label="メールアドレス"
                icon={<IconMail />}
                required
                {...form.getInputProps('email')}
              />
              <PasswordInput
                label="パスワード"
                icon={<IconLock />}
                required
                mt="md"
                {...form.getInputProps('password')}
              />

              <Group position="apart" mt="md">
                <div />
                <Link href="/admin/password_reset" passHref>
                  <Anchor<'a'> size="sm">パスワードを忘れましたか？</Anchor>
                </Link>
              </Group>

              <Button type="submit" fullWidth mt="xl">
                サインイン
              </Button>

              <Transition mounted={state.authFailed} transition="fade">
                {(styles) => (
                  <Box style={styles} mt="md">
                    <Text size="sm" color="red">
                      サインインに失敗しました。
                      メールアドレスとパスワードをご確認ください。
                    </Text>
                  </Box>
                )}
              </Transition>
            </form>
          </Paper>
        </Container>
      </AppShell>
    </>
  );
};

export default LoginPage;
