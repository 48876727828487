import axios from 'axios';

/**
 * ログインを行う
 * @param email メールアドレス
 * @param password パスワード
 */
export async function login(
  email: string,
  password: string
): Promise<Api.User> {
  const response = await axios.post<Api.User>('/api/login', {
    email,
    password,
  });

  return response.data;
}

/**
 * パスワードリセットのためのメールを送信する
 */
export async function sendPasswordResetEmail(email: string): Promise<void> {
  await axios.post('/api/password_reset', { email });
}

/**
 * ログインパスワードを変更する。
 * @param email メールアドレス
 * @param password パスワード
 * @param token パスワードリセットトークン
 */
export async function resetPassword(
  email: string,
  password: string,
  token: string
): Promise<void> {
  await axios.post(`/api/password_reset/${token}`, {
    email,
    password,
  });
}
